import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LandingCard from '../../../Components/LandingCard/LandingCard'
import { LandingCardStyle } from './style'
import { getAllLandingList } from "../../../redux/orderSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const LandingList = () => {
    const landingListData = useSelector((state) => state?.order?.LandingDetails);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    if(!sessionStorage.getItem("loginUser")){
        navigate("/admin");
      }
    useEffect(() => {
        dispatch(getAllLandingList())
        .unwrap()
        .then((res)=> {
            if (res.status === 401) {
                toast.error(`${res?.message}`, {
                  className: "toast-message",
                });
              }
        })
    }, [])
    
    return (
        <>
            <div className='container'>
                <h1 className='text-center'>Landing List</h1>
                <LandingCardStyle>
                    {
                        landingListData?.map((item)=> 
                        <LandingCard landingSiteName = {item?.parentSiteType} siteId = {item?.id} imagePath = {item?.ButtonImageModel[0]?.landing_card_image}/>
                        )
                    }
                </LandingCardStyle>
            </div>
        </>
    )
}

export default LandingList